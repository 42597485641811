import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Helmet } from 'react-helmet';

import { theme } from '../theme';

export const TopLayout = props => (
  <React.Fragment>
    <Helmet>
      <meta
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        name="viewportasdf"
      />
      <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700" rel="stylesheet" />
    </Helmet>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  </React.Fragment>
);
