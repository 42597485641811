export const mediaLink = (slashMedia: string, bitly: string) => {
  if (typeof window === 'undefined') {
    console.error('unable to parse link: ', slashMedia);
    return;
  }

  if (!slashMedia.startsWith('/media')) {
    console.error('this is not a media link: ', slashMedia);
    return;
  }

  if (window.location.hostname !== 'satyrasis.com') {
    return `https://storage.googleapis.com/satyrasis.com${slashMedia}`;
  }

  if (bitly) {
    return `https://bit.ly/${bitly}`;
  }

  return slashMedia;
};
