import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Fragment } from 'react';

interface Props {
  visible: boolean;
  changer: () => void;
}

export const Donate = ({ visible, changer }: Props) => (
  <Fragment>
    <Dialog
      keepMounted
      onClose={changer}
      open={visible}
    >
      <DialogTitle>Feeling Generous?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          We weren&apos;t asking, but since you clicked...
          You can fund Satyrasis&apos; future endeavours by buying the album over at bandcamp (name your own price).
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={changer}>Naw Dog.  Screw you fools.</Button>
        <Button color="primary" onClick={changer} variant="contained">Take Me There</Button>
      </DialogActions>
    </Dialog>
  </Fragment>
);
