import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  find,
  path,
  whereEq,
} from 'ramda';
import React, { useState, FC } from 'react';


import { Download, Format } from '../entities/media';
import { mediaLink } from '../util/redirects';
import { Donate } from './Donate';

const styles = (theme: any) => ({
  media: {
    height: 0,
    paddingTop: '100%',
  },
  root: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  sideCard: {
    display: 'flex',
  },
  sideMedia: {
    height: '192px',
    paddingRight: '150px',
    width: 0,
  },
});


const handleDownload = (format: Format, type: Download['type'], downloads: Download[]) => {
  const download = find(whereEq({ format, type }), downloads);
  if (!download) {
    console.error('cannot find download', { downloads, format, type });
    return;
  }
  return mediaLink(download.url.raw, download.url.bitly);
};

const createChanger = (eventPath: string[], changer: Function) => (x: any) => changer(path(eventPath, x));

export interface AlbumInfo {
  albumCover: string;
  free?: boolean;
  soundCloudSrc: string;
  subtitle: string;
  title: string;
  year: string;
}

interface AudioDownloadCardProps {
  albumInfo: AlbumInfo;
  classes: any;
  downloads: Download[];
}

const AudioDownloadCard: FC<AudioDownloadCardProps> = ({ albumInfo: { albumCover, title, subtitle, free }, classes, downloads }) => {
  const [instrumental, setInstrumental] = useState<boolean>(false);
  const [format, setFormat] = useState<Format>('mp3');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const changeVisible = () => setModalVisible(!modalVisible);
  const href = handleDownload(format, instrumental ? 'instrumental' : 'music', downloads);
  return (
    <Card>
      <Donate changer={changeVisible} visible={modalVisible} />
      <CardMedia
        className={classes.media}
        image={albumCover}
        title={title}
      />

      <CardContent>
        <Typography component="h5" variant="h5">{title}</Typography>
        <Typography component="p">{subtitle}</Typography>

        <Grid container direction="row" justify="space-around" style={{ marginTop: '2em' }}>

          <FormGroup>
            <FormLabel component="legend">Audio Format</FormLabel>
            <RadioGroup
              onChange={createChanger(['target', 'value'], setFormat)}
              value={format}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="MP3 (320kbps)"
                value="mp3"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="FLAC"
                value="flac"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="WAV"
                value="wav"
              />
            </RadioGroup>
          </FormGroup>

          <FormGroup>
            <FormLabel component="legend">Options</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={instrumental}
                  color="primary"
                  onChange={createChanger(['target', 'checked'], setInstrumental)}
                />
              }
              label="Instrumental"
            />
            {free && <FormControlLabel
              control={
                <Checkbox
                  checked
                  color="primary"
                  onChange={changeVisible}
                />
              }
              label="Free"
            />}
          </FormGroup>

        </Grid>
      </CardContent>

      <CardActions>
        <Button color="primary" href={href}>Download Audio</Button>
      </CardActions>
    </Card>
  );
};

export interface SideCardStuff {
  buttonText: string;
  image: string;
  paragraph: string;
  title: string;
  type: 'lyrics' | 'tabs';
  url: {
    bitly: string;
    raw: string;
  }
}

const SoundcloudPlayer: FC<{ src: string }> = ({ src }) => (
  <iframe
    frameBorder="no"
    height="514"
    scrolling="no"
    src={src}
    width="100%"
  />
);

const renderSideCardStuff = ({ title, buttonText, paragraph, image, url }: SideCardStuff) => (classes: any) => (
  <Card className={classes.card}>
    <div className={classes.sideCard}>
      <CardMedia
        className={classes.sideMedia}
        image={image}
      />
      <Grid
        alignItems="flex-start"
        className={classes.sideCardContent}
        container
        direction="column"
        justify="space-between"
      >
        <CardContent>
          <Typography component="h5" variant="h5">
            {title}
          </Typography>
          <Typography component="p">
            {paragraph}
          </Typography>
        </CardContent>

        <Button
          color="primary"
          href={mediaLink(url.raw, url.bitly)}
          style={{
            margin: '0 0 8px 8px',
          }}
        >
          {buttonText}
        </Button>
      </Grid>
    </div>
  </Card>
);

interface DownloadPickerProps {
  albumInfo: AlbumInfo;
  classes: any;
  downloads: Download[];
  sideCardStuff: SideCardStuff[];
}


const DownloadPicker = (props: DownloadPickerProps) => {
  const { albumInfo, classes, downloads, sideCardStuff } = props;
  const [lyricsConfig, tabsConfig] = sideCardStuff;
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>

        <Grid item sm={4}>
          <AudioDownloadCard
            albumInfo={albumInfo}
            downloads={downloads}
            classes={classes}
          />
        </Grid>

        <Grid alignContent="flex-start" container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <SoundcloudPlayer src={albumInfo.soundCloudSrc} />
          </Grid>

          <Grid item sm={6}>
            {renderSideCardStuff(tabsConfig)(classes)}
          </Grid>

          <Grid item sm={6}>
            {renderSideCardStuff(lyricsConfig)(classes)}
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};

export default withStyles(styles)(DownloadPicker);
