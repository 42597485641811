import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import { complement, darken, lighten, saturate } from 'polished';
import { pipe } from 'ramda';

export const sandTan = '#e9dfc4';
const primary = '#746f62';

export const orbitalLowPrimary = '4a6974';

export const complementBlue = pipe(
  complement,
  saturate(0.1),
  darken(0.3),
)(sandTan);

// A custom theme for this app
export const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff',
    },
    error: {
      main: red.A400,
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: complementBlue,
    },
  },
});
