import { Button, Container, Grid, Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import { Link } from 'gatsby';
import { map } from 'ramda';
import React, { useLayoutEffect } from 'react';

const useStyles = makeStyles((theme: any) => ({
  bandcamp: {
    backgroundColor: '#619AA9',
  },
  button: {
    '&:hover': {
      color: 'black',
    },
    color: 'white',
    margin: theme.spacing(0.25),
    textTransform: 'none',
  },
  facebook: {
    backgroundColor: '#3B5998',
  },
  footer: {
    display: 'flex',
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
  icon: {
    fontSize: 12,
    overflow: 'visible',
    width: '1.75em',
  },
  metalArchives: {
    backgroundColor: '#471919',
  },
  soundcloud: {
    backgroundColor: '#FF4E01',
  },
  twitter: {
    backgroundColor: '#00B6F1',
  },
  youtube: {
    backgroundColor: '#D52D27',
  },
}));

interface SocialButton {
  icon: string;
  id: string;
  label: string;
  url: string;
}

const socialButtons: SocialButton[] = [
  {
    icon: 'facebook-f',
    id: 'facebook',
    label: 'Facebook',
    url: 'http://www.facebook.com/satyrasis',
  },
  {
    icon: 'youtube',
    id: 'youtube',
    label: 'YouTube',
    url: 'https://www.youtube.com/user/Satyrasis',
  },
  {
    icon: 'bandcamp',
    id: 'bandcamp',
    label: 'Bandcamp',
    url: 'http://satyrasis.bandcamp.com/',
  },
  {
    icon: 'twitter',
    id: 'twitter',
    label: 'Twitter',
    url: 'https://twitter.com/Satyrasis',
  },
  {
    icon: 'soundcloud',
    id: 'soundcloud',
    label: 'SoundCloud',
    url: 'https://soundcloud.com/satyrasis',
  },
  {
    icon: '',
    id: 'metalArchives',
    label: 'Metal Archives',
    url: 'http://www.metal-archives.com/bands/Satyrasis/117691',
  },
];

const generateButtons = (classes: any) => ({ label, icon, id, url }: SocialButton) => (
  <a
    href={url}
    key={id}
    rel="noopener noreferrer"
    style={{ textDecoration: 'none' }}
    target="_blank"
  >
    <Button
      className={classnames(
        classes[id],
        classes.button,
      )}
      key={id}
      size="small"
      variant="contained"
    >
      {icon && <Icon className={`fab fa-${icon} ${classes.icon}`} />}
      {label}
    </Button>
  </a>
);

export const Footer = () => {
  useLayoutEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#insertion-point-jss'),
    );
  });
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Grid alignItems="center" container justify="center">
          {map(generateButtons(classes), socialButtons)}
        </Grid>
      </Container>
    </footer>
  );
};
