import React, { Fragment, FC } from 'react';
import { Typography } from '@material-ui/core';
import DownloadPicker, { AlbumInfo, SideCardStuff } from './DownloadPicker'
import { Download } from '../entities/media';
import albumCover from '../static/images/OrbitalLow.jpg';
import lyricsCover from '../static/images/lyricsCover.png';
import tabsCover from '../static/images/OrbitalLowTabs.jpg';
import { orbitalLowPrimary } from '../theme';

const albumInfo: AlbumInfo = {
  albumCover,
  free: true,
  soundCloudSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/912126895&amp;color=${orbitalLowPrimary}&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false`,
  subtitle: "Download Satyrasis' 2019 Halloween special edition horror album.",
  title: 'Orbital Low',
  year: '2019',
};

const downloads: Download[] = [
  {
    color: 'primary',
    format: 'mp3',
    primary: true,
    title: '320kbps MP3',
    type: 'music',
    url: {
      bitly: 'SatyrasisOrbitalLow',
      raw: '/media/Satyrasis2019OrbitalLow.zip',
    }
  },
  {
    format: 'flac',
    title: '96k/24bit FLAC',
    type: 'music',
    url: {
      bitly: 'SatyrasisOrbitalLowFLAC',
      raw: '/media/SatyrasisOrbitalLowFLAC.zip',
    }
  },
  {
    format: 'wav',
    title: '96k/24bit WAV',
    type: 'music',
    url: {
      bitly: 'SatyrasisOrbitalLowWAV',
      raw: '/media/SatyrasisOrbitalLowWAV.zip',
    }
  },
  {
    format: 'mp3',
    primary: true,
    title: '320kbps MP3',
    type: 'instrumental',
    url: {
      bitly: 'OrbitalLow',
      raw: '/media/Satyrasis2019OrbitalLow.zip',
    }
  },
  {
    format: 'flac',
    title: '96k/24bit FLAC',
    type: 'instrumental',
    url: {
      bitly: 'SatyrasisOrbitalLowFLAC',
      raw: '/media/SatyrasisOrbitalLowFLAC.zip',
    }
  },
  {
    format: 'wav',
    title: '96k/24bit WAV',
    type: 'instrumental',
    url: {
      bitly: 'SatyrasisOrbitalLowWAV',
      raw: '/media/SatyrasisOrbitalLowWAV.zip',
    }
  },
];

const sideCardStuff: SideCardStuff[] = [
  {
    buttonText: 'Download Lyrics',
    image: lyricsCover,
    paragraph: 'A printable lyrics book with custom artwork and careful typesetting.',
    title: 'Lyrics',
    type: 'lyrics',
    url: {
      bitly: 'SatyrasisOrbitalLowLyrics',
      raw: '/media/SatyrasisOrbitalLowLyrics.pdf',
    }
  },
  {
    buttonText: 'Download Tabs',
    image: tabsCover,
    paragraph: 'Guitar Pro and PDF tabs that perfectly track every song on all instruments.',
    title: 'Tabs',
    type: 'tabs',
    url: {
      bitly: 'SatyrasisOrbitalLowTabs',
      raw: '/media/SatyrasisOrbitalLowTabs.zip',
    }
  },
];

export const OrbitalLowDownloadPicker: FC<{ classes: any }> = ({ classes }) => {

  return (
    <Fragment>

      <Typography className={classes.title} variant="h3">
        {albumInfo.title}
      </Typography>
      <Typography variant="h6">{albumInfo.year}</Typography>
      <DownloadPicker
        albumInfo={albumInfo}
        classes={classes}
        downloads={downloads}
        sideCardStuff={sideCardStuff}
      />
    </Fragment>
  );
}