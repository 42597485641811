import { AppBar, Button, IconButton, Tab, Tabs, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { map } from 'ramda';
import React, { Fragment } from 'react';

import Link from '../components/Link';

const useStyles = makeStyles((theme: any) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    li: {
      listStyle: 'none',
    },
    ul: {
      margin: 0,
      padding: 0,
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(6),
    },
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
}));

interface Page {
  name: string;
  to: string;
}

const pages: Page[] = [
  {
    name: 'About',
    to: '/about',
  },
  {
    name: 'Downloads',
    to: '/downloads',
  },
  {
    name: 'Press',
    to: '/press',
  },
];

const generateNavLinks = (classes: any) => (section: Page) => (
  <Link
    className={classes.link}
    color="textPrimary"
    key={section.name}
    noWrap
    to={section.to}
    variant="button"
  >
    {section.name}
  </Link>
);

export const Nav = () => {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.appBar}
      color="default"
      elevation={0}
      position="static"
    >
      <Toolbar className={classes.toolbar}>
        <Link
          className={classes.toolbarTitle}
          color="inherit"
          noWrap
          to="/"
          variant="button"
        >
          Satyrasis
        </Link>
        <nav>
          {map(generateNavLinks(classes), pages)}
        </nav>
      </Toolbar>
    </AppBar>
  );
};
