import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { Footer } from '../components/Footer';
import { Nav } from '../components/Nav';
import { TopLayout } from '../components/TopLayout';
import { OrbitalLowDownloadPicker } from '../components/OrbitalLowDownloadPicker';


import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import { complement, darken, saturate } from 'polished';
import { pipe } from 'ramda';
import { orbitalLowPrimary } from '../theme';

const sandTan = '#e9dfc4';
const complementBlue = pipe(
  complement,
  saturate(0.1),
  darken(0.3),
)(sandTan);

// A custom theme for this page
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff',
    },
    error: {
      main: red.A400,
    },
    primary: {
      main: `#${orbitalLowPrimary}`,
    },
    secondary: {
      main: complementBlue,
    },
  },
});


const containerStyle: CSSProperties = {
  marginTop: '3em',
}

export default function Default() {
  return (
    <TopLayout>
      <ThemeProvider theme={theme}>
        <Nav />
        <Container maxWidth="lg" style={containerStyle}>
          <OrbitalLowDownloadPicker classes={{}} />
        </Container>
        <Footer />
      </ThemeProvider >
    </TopLayout>
  );
}
